@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css?family=Work+Sans');
@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:200,400,500');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Quicksand', 'Work Sans', 'Source Code Pro', 'Roboto', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f5f9;
  overflow-x: hidden;
}

#root{
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

::selection {
  background: #99f6e4;
  color: #0e7490;
}

:focus-visible{
  border: 2px solid #5eead4;
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .nav_active {
    position: relative;
    padding-right: 1rem;
    color: #5eead4;
    text-decoration-line: underline;
    text-underline-offset: 3px;
    font-weight: 500;
  }
  .nav_inactive{
    position: relative;
    padding-right: 1rem;
    color: #cbd5e1;
    text-decoration-line: none;
  }
  .footer{
    height:fit-content;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    height: 6rem;
    background-color: #020617;
  }
  .info_card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #475569;
    border: none;
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
  }
}